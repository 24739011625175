






















































































































































































import TeamCalendar from "@/components/teams/TeamCalendar.vue";
import handleError from "@/helpers/errors";
import { defineComponent, onMounted, provide, reactive } from "@vue/composition-api";
import { listTeamsWithAdminThumbnails, postInfoToList } from "@/api/team-service";
import CreateTeamDialog from "@/components/teams/CreateTeamDialog.vue";
import EditTeam from "@/components/teams/TeamDialog.vue";
import Learnlink from "@learnlink/interfaces";
import ListAndCreateCourses from "@/components/courses/ListAndCreateCourses.vue";
import TeamFilters from "@/components/teams/TeamFilters.vue";
import TeamList from "@/components/teams/TeamList.vue";
import store from "@/store/store";

export default defineComponent({
  name: "Teams",
  components: {
    TeamCalendar,
    CreateTeamDialog,
    EditTeam,
    ListAndCreateCourses,
    TeamFilters,
    TeamList,
  },
  metaInfo() {
    return {
      title: "Admin / Teams",
    };
  },
  setup() {
    const vm = reactive({
      calendarVisible: false,
      checked: [] as string[],
      createTeamDialogVisible: false,
      filteredTeams: [] as Learnlink.Team.View.Admin.Thumbnail[],
      loading: false,
      message: "",
      refreshing: false,
      selectedTeamID: "",
      sendInfoDialogVisible: false,
      teams: [] as Learnlink.Team.View.Admin.Thumbnail[],
    });

    onMounted(async () => {
      vm.loading = true;
      await mount();
      vm.loading = false;
    });

    function check(ID: string): void {
      vm.checked.push(ID);
    }

    function getFilteredTeams(teams: Learnlink.Team.View.Admin.Thumbnail[]): void {
      vm.filteredTeams = teams;
    }

    async function mount(): Promise<void> {
      vm.refreshing = true;
      vm.teams = await listTeamsWithAdminThumbnails();
      vm.refreshing = false;
    }

    function openCreateTeamDialog(): void {
      vm.createTeamDialogVisible = true;
    }

    async function send(): Promise<void> {
      vm.loading = true;

      store.dispatch("displayAlert", {
        message: "Sender. Dette kan ta litt tid! Vent litt...",
        color: "warning",
      });

      try {
        await postInfoToList(vm.checked, {
          author: store.state.user.uid,
          text: vm.message,
        });
        store.dispatch("displayAlert", {
          message: "Sendt! 🥳",
          color: "success",
        });
        vm.checked = [];
        vm.sendInfoDialogVisible = false;
      }
      catch (e) {
        handleError(e);
      }
      vm.loading = false;
    }

    function uncheck(ID: string): void {
      vm.checked.splice(vm.checked.indexOf(ID), 1);
    }

    provide("check", check);
    provide("mountTeams", mount);
    provide("uncheck", uncheck);

    return {
      getFilteredTeams,
      mount,
      openCreateTeamDialog,
      send,
      uncheck,
      vm,
    };
  },
});
