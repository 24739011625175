














































































































import store from "@/store/store";
import { weekdayFromTimestampEnglish, weekNumberFromTimestamp } from "@/utils/timeUtils";
import { computed, defineComponent, PropType, reactive, watchEffect } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "TeamFilters",
  props: {
    allTeams: {
      type: Array as PropType<Learnlink.Team.View.Admin.Thumbnail[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = reactive({
      filters: {
        availableSlots: "",
        courses: [] as string[],
        hasPros: false,
        location: [] as string[],
        noPros: false,
        parent: "",
        seller: "",
        student: "",
        weekday: [] as Learnlink.Type.Weekday[],
        weekNumber: "",
        teamNameOrID: "",
      },
      locations: [...store.state.platformSettings.teams.locations, { ID: "none", title: "Ingen lokasjon" }],
      weekdays: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "sunday",
      ] as Learnlink.Type.Weekday[],
    });

    function availableSlotsFilter(team: Learnlink.Team.View.Admin.Thumbnail): boolean {
      if (vm.filters.availableSlots) {
        return (team.size - team.parents.length) <= parseInt(vm.filters.availableSlots);
      }
      return true;
    }

    function courseFilter(team: Learnlink.Team.View.Admin.Thumbnail): boolean {
      if (vm.filters.courses.length) return vm.filters.courses.includes(team.course.ID);
      return true;
    }

    const courses = computed(() => store.state.courses.map((course) => {
      return {
        readableTitle: `${course.title} (${course.weeks} ukers kurs)`,
        ...course,
      };
    }));

    function locationFilter(team: Learnlink.Team.View.Admin.Thumbnail): boolean {
      if (vm.filters.location.length) return vm.filters.location.includes(team.location) || (vm.filters.location.includes("none") && !team.location);
      return true;
    }

    function parentFilter(team: Learnlink.Team.View.Admin.Thumbnail): boolean {
      if (vm.filters.parent) return team.parents.some(uid => uid.includes(vm.filters.parent));
      return true;
    }

    function proAmountFilter(team: Learnlink.Team.View.Admin.Thumbnail): boolean {
      if (vm.filters.hasPros && vm.filters.noPros) {
        return true;
      }
      else if (vm.filters.hasPros) {
        return team.sellers.length > 0;
      }
      else if (vm.filters.noPros) {
        return team.sellers.length < 1;
      }
      else {
        return true;
      }
    }

    function sellerFilter(team: Learnlink.Team.View.Admin.Thumbnail): boolean {
      if (vm.filters.seller) {
        return team.sellers.some(uid => uid.includes(vm.filters.seller)) ||
          team.verboseSellers?.some(seller => (seller.firstName + " " + seller.lastName).toLowerCase().includes(vm.filters.seller.toLowerCase())) || false;
      }
      return true;
    }

    function studentFilter(team: Learnlink.Team.View.Admin.Thumbnail): boolean {
      if (vm.filters.student) return team.students.some(uid => uid.includes(vm.filters.student));
      return true;
    }

    function teamNameFilter(team: Learnlink.Team.View.Admin.Thumbnail): boolean {
      if (vm.filters.teamNameOrID) {
        return team.title.toLowerCase().includes(vm.filters.teamNameOrID.toLowerCase()) || team.ID.toLowerCase().includes(vm.filters.teamNameOrID.toLowerCase());
      }
      return true;
    }

    function weekdayFilter(team: Learnlink.Team.View.Admin.Thumbnail): boolean {
      if (vm.filters.weekday.length) {
        const day = weekdayFromTimestampEnglish(team.teamSessions[0]?.startTime);
        return vm.filters.weekday.includes(day);
      }
      return true;
    }

    function weekNumberFilter(team: Learnlink.Team.View.Admin.Thumbnail): boolean {
      if (vm.filters.weekNumber?.length) {
        const firstSessionWeekNumber = weekNumberFromTimestamp(team.teamSessions[0]?.startTime);
        return vm.filters.weekNumber === firstSessionWeekNumber;
      }
      return true;
    }

    watchEffect(() => {
      const _filteredTeams = props.allTeams
        .filter(availableSlotsFilter)
        .filter(courseFilter)
        .filter(locationFilter)
        .filter(parentFilter)
        .filter(proAmountFilter)
        .filter(sellerFilter)
        .filter(studentFilter)
        .filter(teamNameFilter)
        .filter(weekdayFilter)
        .filter(weekNumberFilter);

      emit("filtered-teams", _filteredTeams);
    });

    return {
      courses,
      vm,
    };
  },
});
